/**注册页面 */
import request from '@/utils/request'
// 获取验证码
export function getCaptchaApi(data) {
	return request({
		// url: '/dm/apply/sms/captcha',
		url: '/dm/push/sms/captcha',
		method: 'get',
		params: data
	})
}
// 根据不同组织获取服务包列表
export function getPackListaApi(data) {
	return request({
		url: '/dm/apply/service/pack/' + data.packType,
		method: 'get'
	})
}
// 注册
export function orgRegisterApi(data) {
	return request({
		url: '/dm/apply/org',
		method: 'post',
		data: data
	})
}
// 医院等级选项
export function getHospitalLevelApi(data) {
	return request({
		url: '/term/concept/getConceptsByDomainCode',
		method: 'get',
		params: data
	})
}
// 全国省市区联动数据获取
export function getAddressApi(data) {
	return request({
		url: '/term/concept/region',
		method: 'get',
		params: data
	})
}