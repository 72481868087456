var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "download-data-dialog vertical-middle-dialog",
          attrs: {
            title: "PDBox-药数矩阵用户协议和隐私政策",
            visible: _vm.dialogVisible,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            width: "950px",
            "append-to-body": true,
            center: "",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "content-view" }, [
            _c("p", [
              _vm._v(
                " 欢迎注册成为PDBox-药数矩阵用户！在您注册过程中，您需要完成我们的注册流程并通过点击同意的形式在线签署以下协议，请您务必仔细 阅读、充分理解协议中的条款内容后再点击同意（尤其是以粗体或下划线标识的条款，因为这些条款可能会明确您应履行的义务或对您的 权利有所限制）。 "
              ),
            ]),
            _c("p", [
              _vm._v(
                " 【请您注意】如果您不同意以下协议全部或任何条款约定，请您停止注册。您停止注册后将仅可以浏览我们的商品信息但无法享受我们的 产品或服务。如您按照注册流程提示填写信息，阅读并点击同意上述协议且完成全部注册流程后，即表示您已充分阅读、理解并接受协议 的全部内容，并表明您同意我们可以依据协议内容来处理您的个人信息，并同意我们将您的订单信息共享给为完成此订单所必须的第三方 合作方（详情查看 "
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onDisagree } }, [
                _vm._v("不同意"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onAgree } },
                [_vm._v("同意")]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }