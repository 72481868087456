<!--机构注册成功-->
<template>
	<div class="edit-pwd-success-wrap">
		<div class="svg-wrap">
			<svg-icon icon-class="shenhezhong" fill-color="#FDAC3D" />
		</div>
		<h4>注册资料提交成功，审批通过后将为您创建账号。</h4>
		<div>
			<el-button type="primary" class="first-step-btn findPwd-btn" @click="onBackToLogin">
				<span>关闭</span>
				<span>（{{ countdown }}）</span>
			</el-button>
		</div>
	</div>
</template>
<script>
import Cookies from "js-cookie"
export default {
	data() {
		return {
			countdown: 10
		}
	},
	created() {
		this.onCountdown()
	},
	methods: {
		// 倒计时函数
		onCountdown() {
			let timer = 0
			clearInterval(timer)
			timer = setInterval(() => {
				this.countdown--
				if (this.countdown === 0) {
					clearInterval(timer)
					Cookies.remove('findPwdObj')
					this.$eventBus.$emit('backToLogin', 'editPwdSucess')
				}
			}, 1000)
		},
		onBackToLogin() {
			Cookies.remove('findPwdObj')
			this.$eventBus.$emit('backToLogin', 'editPwdSucess')
		}
	}
}
</script>
<style lang="scss" scoped>
	.edit-pwd-success-wrap {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		align-items: center;
		h4 {
			font-size: 16px;
			font-weight: 500;
			color: #666666;
			margin: 20px 0 30px;
		}
		::v-deep.svg-icon {
			width: 80px;
			height: 80px;
		}
	}
</style>
