var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "edit-pwd-success-wrap" }, [
    _c(
      "div",
      { staticClass: "svg-wrap" },
      [
        _c("svg-icon", {
          attrs: { "icon-class": "shenhezhong", "fill-color": "#FDAC3D" },
        }),
      ],
      1
    ),
    _c("h4", [_vm._v("注册资料提交成功，审批通过后将为您创建账号。")]),
    _c(
      "div",
      [
        _c(
          "el-button",
          {
            staticClass: "first-step-btn findPwd-btn",
            attrs: { type: "primary" },
            on: { click: _vm.onBackToLogin },
          },
          [
            _c("span", [_vm._v("关闭")]),
            _c("span", [_vm._v("（" + _vm._s(_vm.countdown) + "）")]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }