/**机构注册页面 */
// 服务包列表
export const SERVICE_PACK_LIST = [
  {
    code: '1',
    name: '医疗机构',
    list: []
  },
  {
    code: '2',
    name: '卫计委',
    list: []
  },
  {
    code: '3',
    name: '药企',
    list: []
  },
  {
    code: '9',
    name: '其他',
    list: []
  }
]
// 机构类型列表
export const ORG_LIST = [
  {
    code: '1',
    name: '医疗机构'
  },
  {
    code: '2',
    name: '卫计委'
  },
  {
    code: '3',
    name: '药企'
  },
  {
    code: '9',
    name: '其他'
  }
]
