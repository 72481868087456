var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "layout-container register-container" },
    [
      _c("el-header", { staticClass: "register-header" }, [
        _c("div", [
          _c("img", {
            attrs: { src: require("../../../assets/images/logo-pdBox.png") },
          }),
          _c("span", { staticClass: "header-text" }, [
            _vm._v("欢迎注册机构用户"),
          ]),
        ]),
      ]),
      _c(
        "el-main",
        { staticClass: "register-main" },
        [
          _c(
            "transition",
            { attrs: { name: "component-fade", mode: "out-in" } },
            [
              _c(_vm.which_to_show, {
                tag: "component",
                on: { componentChange: _vm.changeHandle },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-footer", { staticClass: "register-footer" }, [
        _c("div", [
          _c("img", {
            staticStyle: {
              width: "20px",
              height: "20px",
              "margin-top": "10px",
              float: "left",
            },
            attrs: { src: require("../../../assets/images/icon.png"), alt: "" },
          }),
          _c("span", { staticStyle: { "padding-left": "5px" } }, [
            _vm._v(_vm._s(_vm.opennessConfig.webSystemLatestVersion)),
          ]),
        ]),
        _c("div", [
          _c("img", {
            staticStyle: {
              width: "20px",
              height: "20px",
              "margin-top": "10px",
              float: "left",
            },
            attrs: {
              src: require("../../../assets/images/beian-icon.png"),
              alt: "",
            },
          }),
          _c(
            "a",
            {
              staticStyle: {
                display: "inline-block",
                "text-decoration": "none",
              },
              attrs: {
                target: "_blank",
                href: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006620",
              },
            },
            [
              _c(
                "p",
                { staticStyle: { "padding-left": "5px", color: "#939393" } },
                [_vm._v("沪公网安备 31010602006620号")]
              ),
            ]
          ),
        ]),
        _c("div", [
          _c(
            "a",
            {
              staticClass: "baNum",
              staticStyle: { padding: "0" },
              attrs: { href: "https://beian.miit.gov.cn", target: "_blank" },
            },
            [_vm._v("沪ICP备20018067号-2")]
          ),
        ]),
        _c("div", [
          _c(
            "span",
            {
              staticStyle: { "font-size": "18px", "vertical-align": "middle" },
            },
            [_vm._v("©")]
          ),
          _vm._v("2022 上海卫心科技有限公司"),
        ]),
        _c("div", [_vm._v("服务协议")]),
        _c("div", [_vm._v("法律声明及隐私权政策")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }