<template>
  <div class="about-login-form-wrap">
    <header class="title-header clearfix">
      <h3 class="fl">注册</h3>
      <el-button type="text" @click="toLoginHandle" class="fr">立即登录</el-button>
      <!-- <h4 class="fr">登录</h4> -->
    </header>
    <el-form
      ref="registerForm"
      :model="formObj"
      :rules="rules"
      label-width="124px"
      class="register-form">
      <el-form-item prop="orgType" label="机构类型">
        <el-radio-group v-model="formObj.orgType" @change="onOrgTypeChange">
          <el-radio v-for="item in orgShowList" :key="item.code" :label="item.code">{{ item.name }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="isShowHospitalLevel" prop="historyLevelConceptId" label="医院等级">
        <el-select v-model="formObj.historyLevelConceptId">
          <el-option
            v-for="item in hospitalLevelList"
            :key="item.conceptId"
            :label="item.conceptVal"
            :value="item.conceptId"/>
        </el-select>
      </el-form-item>
      <el-form-item prop="orgName" label="机构名称">
        <el-input v-model="formObj.orgName" placeholder="输入机构名称" />
      </el-form-item>
      <el-form-item prop="addressCode" label="机构地址">
        <address-cascader @addressChange="addressChange" />
      </el-form-item>
      <el-form-item prop="detailAddress" label="详细地址">
        <el-input v-model="formObj.detailAddress" placeholder="输入详细地址" />
      </el-form-item>
      <el-form-item prop="contactName" label="联系人姓名">
        <el-input v-model="formObj.contactName" placeholder="输入联系人姓名" />
      </el-form-item>
      <el-form-item prop="contactCell" label="手机号">
        <el-input v-model="formObj.contactCell" placeholder="输入手机号" />
      </el-form-item>
      <!-- <el-form-item prop="captcha" label="验证码">
        <el-input v-if="!isShowCaptcha" v-model="formObj.captcha" placeholder="短信验证码">
          <el-button slot="append" style="color: #2B60F8;background: #fff;" @click="onGetcaptcha" type="primary">获取验证码</el-button>
        </el-input>
        <el-input v-else v-model="formObj.captcha">
          <el-button disabled slot="append">{{ countdown }}后重新获取</el-button>
        </el-input>
      </el-form-item> -->
      <el-form-item prop="contactEmail" label="联系人电子邮箱">
        <el-input v-model="formObj.contactEmail" placeholder="输入联系人电子邮箱" />
      </el-form-item>
      <el-form-item  prop="servicePack" label="所选服务包">
        <el-radio-group v-model="formObj.servicePack">
          <el-radio
            v-for="item in servicePackShowList"
            :key="item.packId"
            :label="item.packId"
            class="pack-radio">
            {{ item.packName}}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          :class="submitBtn"
          @click="$event.currentTarget.disabled ? '' : onRegister()"
          v-preventReClick>注册</el-button>
      </el-form-item>
      <el-form-item>
        <el-radio v-model="agree" :label="true" style="white-space: normal;margin-right: 5px;" @click.prevent.native="agreeChange">
          <span>
            已阅读并同意以下协议
          </span>
          <!-- <a style="color: #2B60F8; line-height: 20px;">
            《PDBox-药数矩阵》隐私权政策、
            法律声明、注册协议</a> -->
        </el-radio>
        <a href="/privacyPolicy.html" class="baNum" target="_blank" style="color: #2B60F8; line-height: 20px;">隐私政策</a>
        <span style="color: #2B60F8; line-height: 20px;">、</span>
        <a href="/serviceAgreement.html" class="baNum" target="_blank" style="color: #2B60F8; line-height: 20px;">服务协议</a>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import {
	getCaptchaApi,
	getPackListaApi,
	orgRegisterApi,
  getHospitalLevelApi
} from '@/api/login/register'
import UserAgreement from './UserAgreement'
import { RULES_PHONE, RULES_EMAIL } from '@/utils/rules'
import { ORG_LIST, SERVICE_PACK_LIST } from '@/utils/login/register'
import AddressCascader from '@/components/AddressCascader'
export default {
	name: 'Register',
	components: {
		UserAgreement,
    AddressCascader
	},
	data() {
		return {
      agree: false,
			isShowUserAgreement: false, // 是否显示用户协议
			isShowCaptcha: false, // 获取验证码
			countdown: 60, // 倒计时
			formObj: {
				captchaPrimaryKey: '', // 验证唯一标志,用于获取到的请求的唯一标志
				orgName: '', // 机构名称
        provinceCode: '', // 省-编码
        cityCode: '', // 市-编码
        districtCode: '', // 区-编码
        detailAddress: '', // 详细地址
        addressCode: [], // 地址code数组
        historyLevelConceptId: '', // 医院层级conceptId-当相关类型为医院时进行填充
				contactName: '', // 联系人姓名
				contactCell: '', // 手机号
				captcha: '123456', // 验证码
				contactEmail: '', // 联系人邮箱
				orgType: '1', // 机构类型 1:医疗机构 2:卫计委 3:药企 9-其他
				servicePack: '',
				sysServicePackList: '' // 所选服务包
			},
      hospitalLevelObj: {
        domainCode: 'pub_hospital_level_rough',
        includeSon: 0
      },
			rules: {
				orgName: [
					{ required: true, trigger: "blur", message: "机构名称不能为空" }
				],
        addressCode: [
          { required: true, trigger: ['blur', 'change'], message: "地址不能为空" }
        ],
        historyLevelConceptId: [
          { required: true, trigger: ['blur', 'change'], message: "医院等级不能为空" }
        ],
				contactName: [
					{ required: true, trigger: "blur", message: "联系人姓名不能为空" }
				],
				contactCell: [
					{ required: true, trigger: "blur", message: "手机号不能为空" },
					RULES_PHONE()
				],
				captcha: [
					{ required: true, trigger: "blur", message: "验证码不能为空" }
				],
				contactEmail: [
					{ required: true, trigger: "blur", message: "联系人邮箱不能为空" },
					RULES_EMAIL()
				],
				orgType: [
					{ required: true, trigger: "blur", message: "机构类型不能为空" }
				],
				servicePack: [
					{ required: true, trigger: ['blur', 'change'], message: "服务包不能为空" }
				]
			},
			orgList: this.$deepClone(ORG_LIST),
      orgShowList: [],
      hospitalLevelList: [],
      servicePackList: this.$deepClone(SERVICE_PACK_LIST),
      servicePackShowList: [],
			serviceList: [] // 服务包
		}
	},
	computed: {
		captchaDisabled() {
			return false
		},
    isShowHospitalLevel() {
      if (this.formObj.orgType === '1') {
        return true
      } else {
        return false
      }
    },
    submitBtn() {
      const {
          historyLevelConceptId, orgName, contactName,
          contactCell, captcha, contactEmail, servicePack } = this.formObj
      let len1 = 0
      const len2 = orgName.trim().length
      const len3 = contactName.trim().length
      const len4 = contactCell.trim().length
      const len5 = captcha.trim().length
      const len6 = contactEmail.trim().length
      const len7 = servicePack
      if (this.isShowHospitalLevel) {
        len1 = historyLevelConceptId
      } else {
        len1 = 1
      }
      if (len1 && len2 && len3 && len4 && len5 && len6 && len7 > 0 && this.agree) {
        return 'register-btn form-btn'
      } else {
        return 'register-btn form-btn disabled'
      }
    }
	},
	watch: {
		'formObj.servicePack': function (newVal, oldVal) {
			this.formObj.sysServicePackList = []
			this.formObj.sysServicePackList.push(newVal)
		}
	},
	async created() {
		this.isShowUserAgreement = true
		this.$nextTick(() => {
			if (this.$refs['agreement-comp']) {
				this.$refs['agreement-comp'].dialogVisible = true
			}
		})
    await this.onGetServicePack()
    await this.onGetHospitalData()
	},
	methods: {
		// 获取验证码按钮
		async onGetcaptcha() {
			this.$refs['registerForm'].validateField('contactCell', async (validate) => {
				if (validate) {
					return true
				} else {
					await this.onGetcaptchaData()
				}
			})
		},
		// 获取验证码调接口
		async onGetcaptchaData() {
			this.isShowCaptcha = true
			this.countdown = 60
			this.onCountdown()
			const obj = {
				captchaSceneEnum: 'REGISTER',
				phone: this.formObj.contactCell
			}
			let res = await getCaptchaApi(obj)
			if (res && res.code === 200) {
				this.formObj.captchaPrimaryKey = res.data && res.data.msg
			}
		},
    // 获取医院等级
    async onGetHospitalData() {
      let res = await getHospitalLevelApi(this.hospitalLevelObj)
      if (res.code === 200) {
        this.hospitalLevelList = res.data || []
        // console.log(this.hospitalLevelList)
      }
    },
    // addressChange
    addressChange(code) {
      // console.log(code)
      this.formObj.addressCode = code
      this.formObj.provinceCode = code[0] ? code[0] : ''
      this.formObj.cityCode = code[1] ? code[1] : ''
      this.formObj.districtCode = code[2] ? code[2] : ''
    },
		// 倒计时函数
		onCountdown() {
			let timer = 0
			clearInterval(timer)
			timer = setInterval(() => {
				this.countdown--
				if (this.countdown === 0) {
					clearInterval(timer)
					this.isShowCaptcha = false
				}
			}, 1000)
		},
		async onOrgTypeChange(val) {
			// await this.onGetServicePack()
      this.servicePackShowList = this.orgShowList.find(item => item.code === val).servicePackList
		},
		// 获取服务包
		async onGetServicePack() {
      this.servicePackList = this.$deepClone(SERVICE_PACK_LIST)
      for (let item of this.orgList) {
        let res = await getPackListaApi({ packType: item.code })
        if (res && res.code === 200) {
          this.serviceList = res.data || []
          let packItem = this.servicePackList.find((pack, index) => pack.code === item.code)
          packItem.list = this.$deepClone(this.serviceList)
        }
      }
      for (let i = 0; i < this.servicePackList.length; i++) {
        let pack = this.servicePackList[i]
        if (pack.list.length === 0) {
          // let index = this.orgList.findIndex(i => i.code === pack.code)
          // this.orgList.splice(index, 1)
        } else {
          this.orgShowList.push({
            code: pack.code,
            name: pack.name,
            servicePackList: this.$deepClone(pack.list)
          })
          // this.servicePackShowList = this.$deepClone(pack.list)
        }
      }
      this.servicePackShowList = this.$deepClone(this.orgShowList[0] && this.orgShowList[0].servicePackList)
			// const obj = { packType: this.formObj.orgType }
			// let res = await getPackListaApi(obj)
			// if (res && res.code === 200) {
			// 	this.serviceList = res.data || []
			// }
		},
		// 注册按钮点击
		onRegister() {
      if (!this.agree) {
        this.$message.warning('请勾选用户协议')
        return
      }
			this.$refs['registerForm'].validate(async (valid, object) => {
				if (valid) {
					let res = await orgRegisterApi(this.formObj)
					if (res && res.code === 200) {
						console.log('注册成功')
						this.registerSucess()
					}
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},
		// 注册成功方法处理
		registerSucess() {
			this.$emit('componentChange', 'registerSucess')
		},
		// 同意用户协议
		onAgree() {
			this.isShowUserAgreement = false
			this.$nextTick(() => {
				this.isShowUserAgreement = false
			})
		},
    agreeChange() {
      this.agree = !this.agree
    },
    toLoginHandle() {
			// this.$emit('componentChange', 'login')
      this.$eventBus.$emit('backToLogin', 'registerForm')
		}
	}
}
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/login.scss';
::v-deep.el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
  box-shadow: 0 0 2px 2px #fff;
}
.pack-radio {
  width: calc(50% - 15px);
  margin-right: 15px;
}

</style>