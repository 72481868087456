// -----------------------------   Element Rule 封装   -----------------------------
/**
 * 获取el rule 必填验证实体
 * @param {String} message 错误提示
 * @param {String} type 验证类型，'array'（复选框），'date'（日期选择），文本 string（string element默认，可不设）
 * @param {Boolean} isBlur 是否失去焦点时触发（默认change，下拉菜单，日期等控件只能使用change触发）
 */
export const RULES_REQUIRED = function (message, type, isBlur) {
	let rule = {
		required: true,
		message: message || '此项为必填项',
		trigger: isBlur ? 'blur' : ['blur', 'change']
	}
	if (type) {
		rule.type = type
	}
	return rule
}

/**
 * 获取el rule 文本长度验证
 * @param {Boolean} isChange 是否绑定值改变时触发
 */
export const RULES_LENGTH = function (min, max, message, isChange) {
	let tips
	if (min && max) {
		tips = '长度在 ' + min + ' 到 ' + max + ' 个字'
	} else if (min) {
		tips = '长度不能少于 ' + min + ' 个字'
	} else if (max) {
		tips = '长度不能超过 ' + max + ' 个字'
	}
	// 长度验证规则
	return {
		min: min || '',
		max: max || '',
		message: message || tips,
		validator: Validate.chinaIdentityValid,
		trigger: isChange ? ['blur', 'change'] : 'blur'
	}
}

/**
 * 获取el rule 邮件验证
 * @param {String} message 错误提示
 * @param {Boolean} isChange 是否绑定值改变时触发
 */
export const RULES_EMAIL = function (message, isChange) {
	return { type: 'email', message: message || '请输入正确的邮箱地址', trigger: isChange ? ['blur', 'change'] : 'blur' }
}

/**
 * 获取el rule 网址验证
 * @param {String} message 错误提示
 * @param {Boolean} isChange 是否绑定值改变时触发
 */
export const RULES_URL = function (message, isChange) {
	return { type: 'url', message: message || '请输入正确的网址', trigger: isChange ? ['blur', 'change'] : 'blur' }
}

/**
 * 获取el rule 手机号验证，使用正则表达式实现
 * @param {String} message 错误提示
 * @param {Boolean} isChange 是否绑定值改变时触发
 */
export const RULES_PHONE = function (message, isChange) {
	return {
		message: message || '请输入正确的手机号',
		pattern: '^1(3|4|5|7|8|9)\\d{9}$',
		trigger: isChange ? ['blur', 'change'] : 'blur'
	}
}

/**
 * 获取el rule 数字范围，使用自定义函数实现
 * @param {Boolean} decimal 是否支持小数输入，=false时验证整数
 * @param {Number} decimalLength 支持小数的长度
 * @param {String} message 错误提示
 * @param {Boolean} isChange 是否绑定值改变时触发
 */
export const RULES_NUM_RANGE = function (minValue, maxValue, message, isChange) {
	return {
		message,
		validator: Validate.numberRange(minValue, maxValue),
		trigger: isChange ? ['blur', 'change'] : 'blur'
	}
}

/**
 * 获取el rule 小数规则，使用自定义函数实现
 * @param {Boolean} decimal 是否支持小数输入，=false时验证整数
 * @param {Number} decimalLength 支持小数的长度
 * @param {String} message 错误提示
 * @param {Boolean} isChange 是否绑定值改变时触发
 */
export const RULES_DECIMAL = function (decimal, decimalLength, message, isChange) {
	return {
		message,
		validator: Validate.decimalRule(decimal, decimalLength),
		trigger: isChange ? ['blur', 'change'] : 'blur'
	}
}

/**
 * 获取el rule 身份证验证，使用自定义函数实现
 * @param {Boolean} isChange 是否绑定值改变时触发
 */
export const RULES_CHINA_ID = function (isChange) {
	return {
		validator: Validate.chinaIdentityValid,
		trigger: isChange ? ['blur', 'change'] : 'blur'
	}
}
