<!--用户协议-->
<template>
	<el-dialog
		v-if="dialogVisible"
		title="PDBox-药数矩阵用户协议和隐私政策"
		:visible.sync="dialogVisible"
		:close-on-press-escape="false"
		:close-on-click-modal="false"
		class="download-data-dialog vertical-middle-dialog"
		width="950px"
		:append-to-body="true"
		center
		:before-close="handleClose">
    <div class="content-view">
      <p>
        欢迎注册成为PDBox-药数矩阵用户！在您注册过程中，您需要完成我们的注册流程并通过点击同意的形式在线签署以下协议，请您务必仔细
        阅读、充分理解协议中的条款内容后再点击同意（尤其是以粗体或下划线标识的条款，因为这些条款可能会明确您应履行的义务或对您的
        权利有所限制）。
      </p>
      <p>
        【请您注意】如果您不同意以下协议全部或任何条款约定，请您停止注册。您停止注册后将仅可以浏览我们的商品信息但无法享受我们的
        产品或服务。如您按照注册流程提示填写信息，阅读并点击同意上述协议且完成全部注册流程后，即表示您已充分阅读、理解并接受协议
        的全部内容，并表明您同意我们可以依据协议内容来处理您的个人信息，并同意我们将您的订单信息共享给为完成此订单所必须的第三方
        合作方（详情查看
      </p>
    </div>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onDisagree">不同意</el-button>
			<el-button type="primary" @click="onAgree">同意</el-button>
		</span>
	</el-dialog>
</template>
<script>
export default {
	data() {
		return {
			dialogVisible: false
		}
	},
	methods: {
		// 关闭对话框
		handleClose(done) {
			// console.log(done)
			this.$eventBus.$emit('backToLogin', 'userAgreement')
			this.dialogVisible = false
			// this.$confirm('确认关闭？')
			// 	.then(_ => {
			// 		// 回到登录页
			// 		this.$eventBus.$emit('backToLogin', 'userAgreement')
			// 		done()
			// 	}).catch(_ => {})
		},
		// 不同意
		onDisagree() {
			this.$eventBus.$emit('backToLogin', 'userAgreement')
			this.dialogVisible = false
		},
		// 同意
		onAgree() {
			this.dialogVisible = false
			this.$emit('agree', true)
		}
	}
}
</script>
<style lang="scss" scoped>
.download-data-dialog {
  ::v-deep.el-dialog__body {
    width: auto !important;
    .content-view {
      padding: 15px;
      border: 1px solid #C8D9FA;
      height: 100%;
    }
  }
  ::v-deep.el-dialog__footer {
    background-color: #fff;
    border: none;
    .el-button {
      width: 120px;
      box-shadow: 0px 2px 6px 0px rgba(43, 96, 248, 0.51);
      border-radius: 2px;
    }
  }
}
</style>
