<!--注册页面-->
<template>
<el-container class="layout-container register-container">
	<el-header class="register-header">
		<div>
			<img src="../../../assets/images/logo-pdBox.png" />
			<span class="header-text">欢迎注册机构用户</span>
		</div>
		<!-- <div v-if="showQuickLogin">
			<span>已有账号，</span>
			<el-button type="text" @click="toLoginHandle">立即登录</el-button>
		</div> -->
	</el-header>
	<el-main class="register-main">
		<!-- <el-form
			ref="registerForm"
			:model="formObj"
			:rules="rules"
			label-width="120px"
			class="register-form">
			<el-form-item prop="orgName" label="机构名称">
				<el-input v-model="formObj.orgName" />
			</el-form-item>
			<el-form-item prop="contactName" label="联系人姓名">
				<el-input v-model="formObj.contactName" />
			</el-form-item>
			<el-form-item prop="contactCell" label="手机号">
				<el-input v-model="formObj.contactCell" />
			</el-form-item>
			<el-form-item prop="captcha" label="验证码">
				<el-input v-if="!isShowCaptcha" v-model="formObj.captcha">
					<el-button slot="append" :disabled="captchaDisabled" @click="onGetcaptcha">获取验证码</el-button>
				</el-input>
				<el-input v-else v-model="formObj.captcha">
					<el-button disabled slot="append">{{ countdown }}后重新获取</el-button>
				</el-input>
			</el-form-item>
			<el-form-item prop="contactEmail" label="联系人电子邮箱">
				<el-input v-model="formObj.contactEmail" />
			</el-form-item>
			<el-form-item prop="orgType" label="机构类型">
				<el-radio-group v-model="formObj.orgType" @change="onOrgTypeChange">
					<el-radio v-for="item in orgList" :key="item.code" :label="item.code">{{ item.name }}</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item v-show="serviceList.length > 0" prop="servicePack" label="所选服务包">
				<el-radio-group v-model="formObj.servicePack">
					<el-radio v-for="item in serviceList" :key="item.packId" :label="item.packId">
						{{ item.packName}}
					</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" class="register-btn" @click="onRegister">注册</el-button>
			</el-form-item>
		</el-form> -->
		<transition name="component-fade" mode="out-in">
			<component :is="which_to_show" @componentChange="changeHandle"></component>
		</transition>
		<!--用户协议-->
		<!-- <user-agreement v-if="isShowUserAgreement" ref="agreement-comp" @agree="onAgree" /> -->
	</el-main>
	<el-footer class="register-footer">
    <div>
      <img src="../../../assets/images/icon.png" alt="" style="width: 20px;height: 20px;margin-top:10px;float: left;"/>
      <span style="padding-left:5px;">{{ opennessConfig.webSystemLatestVersion }}</span>
    </div>
    <div>
      <img src="../../../assets/images/beian-icon.png" alt="" style="width: 20px;height: 20px;margin-top:10px;float: left;"/>
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006620"
      style="display:inline-block;text-decoration:none;">
      <p style="padding-left:5px;color:#939393;">沪公网安备 31010602006620号</p></a>
    </div>
    <div>
      <a href="https://beian.miit.gov.cn" target="_blank" style="padding: 0;" class="baNum">沪ICP备20018067号-2</a>
    </div>
    <div><span style="font-size: 18px;vertical-align: middle;">©</span>2022 上海卫心科技有限公司</div>
    <div>服务协议</div>
    <div>法律声明及隐私权政策</div>
	</el-footer>
</el-container>
</template>
<script>
import {
	getCaptchaApi,
	getPackListaApi,
	orgRegisterApi
} from '@/api/login/register'
import UserAgreement from './UserAgreement'
import RegisterForm from './RegisterForm'
import RegisterSucess from './RegisterSucess'
import { RULES_PHONE, RULES_EMAIL } from '@/utils/rules'
import { opennessConfigList } from "@/api/system/config";
export default {
	name: 'Register',
	components: {
		UserAgreement,
		RegisterForm,
		RegisterSucess
	},
	data() {
		return {
      /*系统开发配置信息*/
      opennessConfig:{
        /*安卓app下载二维码base64*/
        androidAppDownloadQrCode:"",
        webSystemLatestVersion: '' // pc版本号
      },
			which_to_show: RegisterForm,
			isShowUserAgreement: false, // 是否显示用户协议
			isShowCaptcha: false, // 获取验证码
			countdown: 60, // 倒计时
			formObj: {
				captchaPrimaryKey: '', // 验证唯一标志,用于获取到的请求的唯一标志
				orgName: '', // 机构名称
				contactName: '', // 联系人姓名
				contactCell: '', // 手机号
				captcha: '', // 验证码
				contactEmail: '', // 联系人邮箱
				orgType: '', // 机构类型 1:医疗机构 2:卫计委 3:药企 9-其他
				servicePack: '',
				sysServicePackList: '' // 所选服务包
			},
			rules: {
				orgName: [
					{ required: true, trigger: "blur", message: "机构名称不能为空" }
				],
				contactName: [
					{ required: true, trigger: "blur", message: "联系人姓名不能为空" }
				],
				contactCell: [
					{ required: true, trigger: "blur", message: "手机号不能为空" },
					RULES_PHONE()
				],
				captcha: [
					{ required: true, trigger: "blur", message: "验证码不能为空" }
				],
				contactEmail: [
					{ required: true, trigger: "blur", message: "联系人邮箱不能为空" },
					RULES_EMAIL()
				],
				orgType: [
					{ required: true, trigger: "blur", message: "机构类型不能为空" }
				],
				servicePack: [
					{ required: true, trigger: "blur", message: "服务包不能为空" }
				]
			},
			orgList: [
				{
					code: '1',
					name: '医疗机构'
				},
				{
					code: '2',
					name: '卫计委'
				},
				{
					code: '3',
					name: '药企'
				},
				{
					code: '9',
					name: '其他'
				}
			],
			serviceList: [] // 服务包
		}
	},
	computed: {
		componentName() {
			if (this.which_to_show === RegisterForm) {
				return RegisterForm
			} else if (this.which_to_show === RegisterSucess) {
				return RegisterSucess
			}
		},
		showQuickLogin() {
			if (this.which_to_show === RegisterSucess) {
				return false
			} else if (this.which_to_show === RegisterForm) {
				return true
			}
		},
		captchaDisabled() {
			return false
		}
	},
	watch: {
		'formObj.servicePack': function (newVal, oldVal) {
			this.formObj.sysServicePackList = []
			this.formObj.sysServicePackList.push(newVal)
		}
	},
	created() {
    this.getOpennessConfig()
		this.isShowUserAgreement = true
		this.$nextTick(() => {
			if (this.$refs['agreement-comp']) {
				this.$refs['agreement-comp'].dialogVisible = true
			}
		})
	},
	methods: {
    /*获取系统开放配置信息*/
    getOpennessConfig(){
      opennessConfigList().then(res=>{
        this.opennessConfig=res.data
      })
    },
		changeHandle(component) {
			if (component === 'registerForm') {
				this.which_to_show = RegisterForm
			} else if (component === 'registerSucess') {
				this.which_to_show = RegisterSucess
			}
		},
		toLoginHandle() {
			this.$emit('componentChange', 'login')
		},
		// 获取验证码按钮
		async onGetcaptcha() {
			this.$refs['registerForm'].validateField('contactCell', async (validate) => {
				if (validate) {
					return true
				} else {
					await this.onGetcaptchaData()
				}
			})
		},
		// 获取验证码调接口
		async onGetcaptchaData() {
			this.isShowCaptcha = true
			this.countdown = 60
			this.onCountdown()
			const obj = {
				captchaSceneEnum: 'REGISTER',
				phone: this.formObj.contactCell
			}
			let res = await getCaptchaApi(obj)
			if (res && res.code === 200) {
				this.formObj.captchaPrimaryKey = res.data && res.data.msg
			}
		},
		// 倒计时函数
		onCountdown() {
			let timer = 0
			clearInterval(timer)
			timer = setInterval(() => {
				this.countdown--
				if (this.countdown === 0) {
					clearInterval(timer)
					this.isShowCaptcha = false
				}
			}, 1000)
		},
		async onOrgTypeChange() {
			await this.onGetServicePack()
		},
		// 获取服务包
		async onGetServicePack() {
			const obj = { packType: this.formObj.orgType }
			let res = await getPackListaApi(obj)
			if (res && res.code === 200) {
				this.serviceList = res.data || []
			}
		},
		// 注册按钮点击
		onRegister() {
			this.$refs['registerForm'].validate(async (valid) => {
				if (valid) {
					let res = await orgRegisterApi(this.formObj)
					if (res && res.code === 200) {
						console.log('注册成功')
						this.registerSucess()
					}
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},
		// 注册成功方法处理
		registerSucess() {
			this.$alert('', '注册结果', {
				dangerouslyUseHTMLString: true,
				confirmButtonText: '确定',
				beforeClose: (action, instance, done) => {
					if (action === 'confirm') {
						done()
						this.$emit('componentChange', 'login')
					} else {
						done()
					}
				}
			})
			/* const h = this.$createElement
			this.$msgbox({
				title: '注册结果',
				message: h('div', null, [
					h('<svg-icon icon-class="chenggong" />'),
					h('p', { style: 'color: #333' }, '注册资料提交成功，审批中！')
				]),
				confirmButtonText: '确定',
				beforeClose: (action, instance, done) => {
					if (action === 'confirm') {
						done()
						this.$emit('componentChange', 'login')
					} else {
						done()
					}
				}
			}) */
		},
		// 同意用户协议
		onAgree() {
			this.isShowUserAgreement = false
			this.$nextTick(() => {
				this.isShowUserAgreement = false
			})
		}
	}
}
</script>
<style lang="scss" scoped>
.baNum:hover{
  text-decoration:underline;
}
.register-container {
	.register-header {
		height: 50px !important;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #FFFFFF;
		box-shadow: 0px 4px 6px 0px rgba(189, 192, 199, 0.7);
		img {
			vertical-align: middle;
		}
		.header-text {
			display: inline-block;
			border-left: 2px solid #C8D9FA;
			font-size: 18px;
			font-weight: 500;
			color: #333333;
			margin-left: 10px;
			padding-left: 10px;
		}
	}
	.register-main {
		.register-form {
			width: 50%;
			margin: 0 auto;
			::v-deep.register-btn {
				width: 100%;
        border: 1px solid #DCDFE6 !important;
				background: linear-gradient(90deg, #00AAFF, #2B60F8);
				border-radius: 20px;
        &.disabled {
          background: linear-gradient(90deg, #00AAFF, #2B60F8);
          box-shadow: 0px 4px 8px 0px rgba(40, 128, 215, 0.5);
          opacity: 0.5;
          border: none;
        }
			}
		}
	}
	.register-footer {
    height: 40px!important;
    line-height: 40px;
    width: 100%;
    height: 40px;
    background:#EBEFF7;
    opacity: 0.8;
    /* position: fixed; */
    display: flex;
    justify-content: center;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #999999;
    font-family: Arial;
    font-size: 14px;
    letter-spacing: 1px;
    div {
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
	}
}
</style>
